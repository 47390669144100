<div class="page-container container-fluid p-0">
  <!--Header-->
  <nav class="navbar header-format py-3 px-4 px-md-5">
    <div class="limited-width">
      <div class="navbar-brand header-banner pointerOnHover" (click)="doReset()">
        <img src="./assets/bny/bny_logo_tealarrow_white_RGB_2x.png" class="header-logo pe-2" alt="BNY Logo" />
        <span class="ah-home ps-5">Authentication Hub</span>
      </div>
      <div class="ms-auto" *ngIf="isAuthenticated">
        <div class="logout-btn pointerOnHover rounded-3 mb-0" (click)="doLogout()">
          <div>
            <fa-icon [icon]="logoutButton" title="Logout"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <!--Content Body-->
  <div class="component-container py-3 px-4 p-md-5">
    <div class="limited-width">
      <!-- Embed Page Contents -->
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- Footer-->
  <footer class="ah-footer container-fluid py-2">
    <div>
      <ul id="footer-links" class="mb-2">
        <li><a href="https://www.bnymellon.com/us/en/data-privacy.html">Privacy Statement</a></li>
        <li><a class="pointerOnHover" routerLink="/cookies">Cookie Policy</a></li>
        <li><a href="https://www.bnymellon.com/us/en/terms-of-use.html">Terms of Use</a></li>
        <li><a class="pointerOnHover" routerLink="/sms-terms-of-service">SMS Terms of Service</a></li>
        <li><a class="pointerOnHover" routerLink="/help">Login Assistance</a></li>
      </ul>
    </div>
    <div class="bnym-copyright">© 2022 The Bank of New York Mellon Corporation.</div>
  </footer>

</div>
