import {Component} from '@angular/core';
import {DisplayService} from "./service/display.service";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Subscription} from "rxjs";
import {AuthState, AuthStateService} from "./service/auth-state.service";
import {ResetService} from "./service/reset.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {

  isAuthenticated: boolean;
  logoutButton = faSignOutAlt;
  private authStatusSource: Subscription;

  constructor(    private displayService: DisplayService,
                  private authStateService: AuthStateService,
                  private resetService: ResetService,
                  private router: Router)
  {
    // Subscribe to user auth status
    this.authStatusSource = authStateService.getAuthStatusSource()
      .subscribe((authStatus) => {
        // Check authentication state every time
        this.isAuthenticated = this.authStateService.isAuthenticated();

        if (authStatus === AuthState.NONE) {
          this.displayService.flowState = null;
          this.displayService.userName = null;
        }
      });
  }

  doLogout() {
    this.resetService.clearQueryParams();
    this.authStateService.doLogout();
  }

  doReset() {
    if (sessionStorage.getItem("x-flow") === "direct" || this.authStateService.getAuthStatus() === AuthState.NONE) {
      this.router.navigate(["home"]);

    } else {
      this.resetService.restartFlow();
    }
  }
}
